import React from 'react'
import {Card, Button} from 'reactstrap'

const Merch = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100vw',
        paddingTop: 100,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
          maxWidth: 800,
        }}
      >
        <Card style={{margin: 13}}>
          <img src="/assets/adidas-performance-polo-shirt-white-front-639dcadef0c25.jpg"></img>
          <p>Blaze Rugby Embroidered Polo</p>
          <Button>Add to Cart</Button>
        </Card>
        <Card style={{margin: 13}}>
          <img src="/assets/adidas-performance-polo-shirt-white-front-639dcadef0c25.jpg"></img>
          <p>Blaze Rugby Hat</p>
          <Button>Add to Cart</Button>
        </Card>
        <Card style={{margin: 13}}>
          <img src="/assets/adidas-performance-polo-shirt-white-front-639dcadef0c25.jpg"></img>
          <p>Blaze Rugby Fold Up Jacket</p>
          <Button>Add to Cart</Button>
        </Card>
      </div>
    </div>
  )
}

export default Merch
