import React, {useState, Fragment} from 'react'
import {Card, Input} from 'reactstrap'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'

const SignUp = () => {
  const [username, setUsername] = useState('')
  const [isCodeEnter, setIsCodeEnter] = useState(false)
  const [isSignUp, setIsSignUp] = useState(true)
  const navigate = useNavigate()
  return (
    <div>
      <Card>
        <form
          method="POST"
          onSubmit={async (evt) => {
            evt.preventDefault()
            if (!isCodeEnter) {
              const signUp = await axios.post('/api/cognito/signup', {
                username: evt.target.username.value,
                password: evt.target.password.value,
              })
              if (signUp.status === 200) {
                setIsCodeEnter(true)
              }
            } else {
              const confirmCode = await axios.post(
                '/api/cognito/confirm-signup',
                {
                  confirmationCode: evt.target['confirm-code'].value,
                  username: username,
                }
              )
              console.log('after submit', confirmCode)
            }
          }}
        >
          {!isCodeEnter ? (
            <Fragment>
              <Input
                name="username"
                onChange={(evt) => {
                  setUsername(evt.target.value)
                }}
              ></Input>
              <Input name="password"></Input>
              <button type="submit">sign up</button>
            </Fragment>
          ) : (
            <Fragment>
              <Input name="confirm-code"></Input>

              <button type="submit">confirm</button>
            </Fragment>
          )}
        </form>
      </Card>
    </div>
  )
}

export default SignUp
