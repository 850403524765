import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {logout} from '../store'
import {Button} from 'reactstrap'
import {RiShoppingCart2Fill} from 'react-icons/ri'

const Navbar = ({handleClick, isLoggedIn}) => (
  <div
    className="navbar"
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      position: 'fixed',
      top: 0,
      zIndex: 13,
      background: 'rgb(0,0,0)',
      background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
      // boxShadow: '-2px 26px 41px 16px rgba(0,0,0,0.44)',
    }}
  >
    <div style={{flex: 1}}>
      <img src="https://www.chicagoblazerugby.com/uploads/1/0/3/6/10364966/published/blaze-header_1.png?1488745273" />
    </div>
    {/* <h1>BOILERMAKER</h1> */}
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 5,
      }}
    >
      {isLoggedIn ? (
        <div>
          {/* The navbar will show these links after you log in */}
          <Link to="/home">Home</Link>
          <a href="#" onClick={handleClick}>
            Logout
          </a>
        </div>
      ) : (
        <div>
          {/* The navbar will show these links before you log in */}
          <Link to="/home" style={{margin: 13}}>
            Home
          </Link>
          <Link to="/club" style={{margin: 13}}>
            Club
          </Link>
          <Link to="/home" style={{margin: 13}}>
            Firehouse 7s
          </Link>
          <Link to="/home" style={{margin: 13}}>
            Fixtures & Scores
          </Link>
          <Link to="/merch" style={{margin: 13}}>
            Blaze Store
          </Link>
          {/* <Link to="/signup">Cart</Link> */}
          {/* <Link to="/signup">Club</Link> */}
        </div>
      )}
    </div>
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Link to="/signup">Sign up/Log in</Link>
      <Button
        style={{
          height: 43,
          width: 43,
          border: 'none',
          backgroundColor: 'transparent',
          marginLeft: 7,
        }}
      >
        <RiShoppingCart2Fill style={{height: '100%', width: '100%'}} />
      </Button>
    </div>
    <hr />
  </div>
)

/**
 * CONTAINER
 */
const mapState = (state) => {
  return {
    isLoggedIn: !!state.user.id,
  }
}

const mapDispatch = (dispatch) => {
  return {
    handleClick() {
      dispatch(logout())
    },
  }
}

export default connect(mapState, mapDispatch)(Navbar)

/**
 * PROP TYPES
 */
Navbar.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
}
