import React from 'react'
import {Card, Input, Button} from 'reactstrap'
const Login = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        className="log-card"
        style={{
          height: '50vh',
          width: '100%',
          maxWidth: 500,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: 13,
        }}
      >
        {/* <div style={{flex: 1}}> */}
        <h3 style={{marginTop: 13}}>Log in to Blaze Rugby Club</h3>
        <img style={{height: 81}} src="/assets/Chicago_Blaze_logo.png"></img>
        {/* </div> */}

        <form
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: '13%',
          }}
        >
          <div style={{width: '100%'}}>
            <p style={{width: '90%', marginInline: '5%'}}>Email</p>
            <Input
              placeholder="Enter your username"
              style={{width: '90%', marginInline: '5%'}}
            ></Input>
          </div>
          <div style={{width: '100%'}}>
            <p style={{width: '90%', marginInline: '5%'}}>Password</p>
            <Input
              placeholder="Enter your password"
              style={{width: '90%', marginInline: '5%'}}
            ></Input>
          </div>
          <Button type="submit" style={{width: '90%', marginInline: '5%'}}>
            Login
          </Button>
        </form>
      </Card>
    </div>
  )
}

export default Login
