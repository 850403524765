import React, {useEffect, useState, useCallback, useRef} from 'react'
import debounce from 'lodash.debounce'
import {Card, Button} from 'reactstrap'

const Splash = () => {
  const [mult, setMult] = useState(1)
  const [hideSplash, setHideSplash] = useState(false)
  const badge = useRef(null)
  useEffect(() => {
    const vidDim = 1080 / 1920
    const screenDim = window.innerHeight / window.innerWidth

    console.log(vidDim, screenDim)
    const multy = screenDim / vidDim + 1
    setMult(multy)
  }, [])
  const hide = () => {
    badge.current.classList.add('remove-badge')
    setTimeout(() => {
      setHideSplash(true)
    }, 3000)
  }
  const debounceHide = useCallback(debounce(hide, 2000), [])
  return (
    <div>
      <div
        onMouseMove={() => {
          if (hideSplash) {
            setHideSplash(false)
            badge.current.classList.remove('remove-badge')
          }
          debounceHide()
        }}
        style={{
          height: '100vh',
          width: '100vw',
          objectFit: 'fill',
          overflow: 'hidden',
          maxHeight: window.innerHeight,
        }}
      >
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%',
            minWidth: '100%',
          }}
        ></div> */}

        <div
          ref={badge}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            background: 'rgb(0,0,0)',
            background:
              'radial-gradient(circle, rgba(0,0,0,0.14609593837535018) 28%, rgba(0,0,0,1) 69%)',
            // display: hideSplash && 'none',
          }}
          className="badge-title"
        >
          <img
            style={{
              //   marginLeft: 100,
              height: 'auto',
              width: '31vw',
              maxWidth: 310,
            }}
            src="/assets/Chicago_Blaze_logo.png"
          ></img>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: 'clamp(48px, 10vw, 70px)',

              fontFamily: "'Merriweather Sans', sans-serif",
              marginLeft: 7,
            }}
          >
            <h1 style={{margin: 0}}>Blaze</h1>
            <h1 style={{margin: 0}}>Rugby</h1>
            <h1 style={{margin: 0}}>Club</h1>
          </div>
        </div>
        <div
          style={{
            minHeight: '100vh',
            // maxWidth: '100%',
            minWidth: '100%',
            height: 'auto',
            width: 'auto',
            position: 'absolute',
            overflow: 'hidden',
            top: '50%',

            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: -1,
          }}
        >
          <video
            // style={{}}
            src="/assets/blaze-headerwebm.webm"
            autoPlay="true"
            muted="true"
            style={{height: '100%'}}
            loop
          ></video>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'white',
          width: '100vw',
          //   height: 200,
          paddingTop: 28,
          paddingBottom: 28,
          backgroundColor: 'black',
        }}
      >
        <h1>Chicago Blaze Rugby Club</h1>
        <div
          style={{
            overflow: 'hidden',
            maxHeight: window.innerHeight,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            width: '100%',
            flexWrap: 'wrap',

            //   padding,
          }}
        >
          <Card
            style={{
              flex: 1,
              maxWidth: 500,
              margin: '5%',
              minWidth: 380,
              width: '90%',
              color: 'black',
            }}
          >
            <h4>To be added</h4>
            {/* <Button>Click Here to Join</Button> */}
          </Card>
          <Card
            style={{
              flex: 1,
              maxWidth: 500,
              margin: '5%',
              minWidth: 380,
              width: '90%',
              color: 'black',
            }}
          >
            <h4>To be added</h4>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Splash
