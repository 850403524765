import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
// import {Login, UserHome} from './components'
import SignUp from './components/sign-up'
import {me} from './store'
import {
  Route,
  Routes as Switch,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import SignIn from './components/sign-in'
import Splash from './components/splash'
import Login from './components/login'
import Logup from './components/logup'
import Club from './components/club'
import Merch from './components/merch'

/**
 * COMPONENT
 */
class Routes extends Component {
  componentDidMount() {
    this.props.loadInitialData()
  }

  render() {
    const {isLoggedIn} = this.props

    return (
      <Fragment>
        <Switch>
          {/* Routes placed here are available to all visitors */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Logup />} />
          <Route path="/home" element={<Splash />} />
          <Route path="/club" element={<Club />} />
          <Route path="/merch" element={<Merch />} />

          {/* Displays our Login element as a fallback */}
          <Route path="*" element={<Splash />} />
        </Switch>
      </Fragment>
    )
  }
}

/**
 * CONTAINER
 */
const mapState = (state) => {
  return {
    // Being 'logged in' for our purposes will be defined has having a state.user that has a truthy id.
    // Otherwise, state.user will be an empty object, and state.user.id will be falsey
    isLoggedIn: !!state.user.id,
  }
}

const mapDispatch = (dispatch) => {
  return {
    loadInitialData() {
      dispatch(me())
    },
  }
}

// The `withRouter` wrapper makes sure that updates are not blocked
// when the url changes
export default withRouter(connect(mapState, mapDispatch)(Routes))

/**
 * PROP TYPES
 */
Routes.propTypes = {
  loadInitialData: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()
    return <Component {...props} router={{location, navigate, params}} />
  }

  return ComponentWithRouterProp
}
